/*global chrome*/

import "./general.css";
import closeIcon from "../../assets/close-info.png";
import emailIcon from "../../assets/email.png";
import startDayIcon from "../../assets/start-day-icon.png";
import expiredDayIcon from "../../assets/expired-day-icon.png";
import mochiIcon from "../../assets/Mochimeow.png";
import avaNotLogin from "../../assets/not-login.svg";

import defaultAva from "../../assets/default-avatar.png";
import { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApiClient } from "../../AppClient";

import PrimaryButton from "../commons/PrimaryButton";

function getParameterQuery() {
	let parameter = {};
	const params = new URLSearchParams(window.location.search);
	for (const param of params) {
		parameter[param[0]] = param[1];
	}
	return parameter;
}

function Information() {
	const params = getParameterQuery();
	let navigate = useNavigate();
	let checkNewTab = true;
	let checkDict = "en-vn";

	const accountState = useSelector((state) => state.account);
	const ORIGIN = process.env.REACT_APP_ORIGIN;
	const URL_LOGIN = process.env.REACT_APP_LOGIN;
	const Client = new ApiClient();
	const getCookie = (cookieName) => {
		const cookieNameArray = document.cookie.split(cookieName + "=");
		if (cookieNameArray.length === 2) {
			return cookieNameArray[1].split(";")[0];
		} else {
			return;
		}
	};
	const setCookie = (cookieName, cookieValue, day) => {
		document.cookie = cookieName + "=; Max-Age=0";
		const valuedCookie = cookieName + "=" + cookieValue;
		const date = new Date();
		date.setTime(+date + day * 86400000);
		document.cookie =
			valuedCookie +
			"; expires=" +
			date.toGMTString() +
			"; domain=" +
			ORIGIN +
			";path=/";
	};
	const { displayName, avatar, expiredDay, email, startDay, userToken } =
		accountState;
	const disableNewTab = getCookie("disableNewTab");
	const disableNewTabParams = params.disableNT;
	if (typeof disableNewTabParams !== "undefined") {
		setCookie("disableNewTab", disableNewTabParams, 60);
		window.close(window.location.href);
		const checkParams = disableNewTabParams === disableNewTab;
		checkNewTab = checkParams
			? disableNewTab == "0"
			: disableNewTabParams == "0";
	} else if (disableNewTab !== "") {
		const isDisable = disableNewTab == "1";
		checkNewTab = !isDisable;
	} else {
		setCookie("disableNewTab", 1, 60);
	}
	const defaultDict = getCookie("defaultDict");
	if (defaultDict !== "") {
		checkDict = defaultDict;
	} else {
		setCookie("defaultDict", "en-vn", 60);
	}

	useEffect(() => {
		setSelectDefaultDict(checkDict);
	}, [checkDict]);
	useEffect(() => {
		setSelectNewTab(checkNewTab);
	}, [checkNewTab]);
	const [selectNewTab, setSelectNewTab] = useState(checkNewTab);
	const [selectDefaultDict, setSelectDefaultDict] = useState(checkDict);
	const handleChangeNewTab = () => {
		// console.log("selectNewTab", selectNewTab);
		const newStatus = !selectNewTab;
		const cookieStatus = newStatus ? 0 : 1;
		setCookie("disableNewTab", cookieStatus, 60);
		// document.cookie = "disableNewTab=; Max-Age=0";

		// const disableNewTab = "disableNewTab=" + cookieStatus;
		// const date = new Date();
		// date.setTime(+date + 60 * 86400000);
		// document.cookie =
		//   disableNewTab +
		//   ";expires=" +
		//   date.toGMTString() +
		//   ";domain=" +
		//   ORIGIN +
		//   ";path=/";

		// window.localStorage.setItem("disableNewTab", newStatus ? 0 : 1);
		setSelectNewTab(!selectNewTab);
	};
	const handleChangeDict = (e) => {
		const value = e.target.value;
		setSelectDefaultDict(value);
		setCookie("defaultDict", value, 60);
	};
	const callApiLogut = () => {
		Client.post("v3.1/logout", { user_token: userToken });
	};
	const handleLogOut = () => {
		callApiLogut();
		document.cookie =
			"mochiToken=; Max-Age=0; domain=" + ORIGIN + "; path=/";
		document.cookie =
			"disableNewTab=; Max-Age=0; domain=" + ORIGIN + "; path=/";
		document.cookie =
			"defaultDict=; Max-Age=0; domain=" + ORIGIN + "; path=/";
		// window.location.href = `https://accounts-test.mochidemy.com?url-callback=${ URL_LOGIN }`;
		var laserExtensionId = process.env.REACT_APP_EXTENSION_ID;
        const extensionBox = document.querySelector(".Oq465NRHaOKegSCssrnhCql2WEIDuYU0");
        if (extensionBox) {
            laserExtensionId = extensionBox.getAttribute("id");
        }
		console.log("id extension", laserExtensionId);
		chrome.runtime.sendMessage(laserExtensionId, { msg: "LogOut" });
		window.close();
	};

	return (
		<div
			className="user-information"
			style={{
				backgroundImage: 'url("./images/BG_extension_2.png")',
			}}
		>
			<Row>
				<Col
					md={{ span: 6, offset: 3 }}
					className="information-page-wrapper"
				>
					<div className="information-title-wrapper">
						<img
							src={closeIcon}
							alt="close-icon"
							className="info-close-icon"
							onClick={() => {
								window.close(window.location.href);
							}}
						/>
						<p className="info-title">Thông tin tài khoản</p>
					</div>

					<div className="user-info-wrapper">
						<div className="avatar-wrapper">
							<div>
								{userToken ? (
									<img
										src={avatar ? avatar : defaultAva}
										alt="avatar"
										className={`user-avatar ${
											expiredDay ? "paid-bg" : "free-bg"
										}  ${!userToken ? "not-login" : ""}`}
									/>
								) : (
									<img
										src={avaNotLogin}
										alt="avatar"
										className={`user-avatar free-bg not-login`}
									/>
								)}
							</div>
							<div
								className={`user-account ${
									expiredDay ? "paid-bg" : "free-bg"
								}`}
							>
								{userToken ? (
									<p>
										{expiredDay
											? "Premium Account"
											: "Free User"}
									</p>
								) : (
									<p>Guest account</p>
								)}
							</div>
						</div>
						{userToken && (
							<div className="info-wrapper">
								<h5 className="info-name">
									{displayName
										? displayName.toUpperCase()
										: ""}
								</h5>
								<div className="info-email info-div">
									<img
										src={emailIcon}
										className="info-icon"
										alt="email"
									></img>
									<p className="info-content"> {email}</p>
								</div>
								<div className="info-start-day info-div">
									<img
										src={startDayIcon}
										className="info-icon"
										alt="startDay"
									></img>
									<p className="info-content">
										{" "}
										Ngày kích hoạt: {startDay}
									</p>
								</div>
								{expiredDay && (
									<div className="expiredDayIcon-expired-day info-div">
										<img
											src={expiredDayIcon}
											className="info-icon"
											alt="expiredDay"
										></img>
										<p className="info-content">
											Ngày hết hạn: {expiredDay}
										</p>
									</div>
								)}
							</div>
						)}
					</div>
					{/* <div className="info-setting-wrapper">
                        <div className="setting-dictionary">
                            <p className="dict-title boldText">Chọn từ điển mặc định</p>
                            <select
                                className="dict-select boldText"
                                onChange={handleChangeDict}
                                value={selectDefaultDict}
                            >
                                <option value="en-vn" className="dict-option boldText">
                                    Từ điển Anh - Việt
                                </option>
                                <option value="en-en" className="dict-option boldText">
                                    Từ điển Anh - Anh
                                </option>
                            </select>
                        </div>

                        <div className="setting-new-tab">
                            <p className="new-tab-title boldText">Chức năng Mochi New Tab</p>
                            <label className="switch">
                                <div className="toggle">
                                    <input
                                        className="toggle-state"
                                        type="checkbox"
                                        name="check"
                                        check="check"
                                        checked={selectNewTab}
                                        onChange={handleChangeNewTab}
                                    />
                                    <div className="toggle-inner">
                                        <div className="indicator"></div>
                                    </div>
                                    <div className="active-bg"></div>
                                </div>
                                <p>{selectNewTab ? "On" : "Off"}</p>
                            </label>
                        </div>
                    </div> */}

					{userToken ? (
						<>
							<div className="info-web-wrapper">
								<p className="boldText">
									Truy cập website MochiMochi để học và ôn tập
									với Thời Điểm Vàng
								</p>
								<PrimaryButton
									onClick={() =>
										window.open(
											`https://mochien-web-test.mochidemy.com/oauth?user_token=${userToken}`,
											"_blank"
										)
									}
								>
									Mở website ngay
								</PrimaryButton>
							</div>
							<p
								className="boldText logOutText"
								onClick={handleLogOut}
							>
								Đăng xuất
							</p>
						</>
					) : (
						<div className="user-info-wrapper-not-login">
							<p>
								Đăng nhập tài khoản học MochiMochi để mở khoá
								tất cả các tính năng
							</p>
							<PrimaryButton
								onClick={() =>
									(window.location.href =
										process.env.REACT_APP_ROOT +
										"?url-callback=" +
										process.env.REACT_APP_LOGIN)
								}
							>
								Đăng nhập ngay
							</PrimaryButton>
						</div>
					)}
					<img
						src={mochiIcon}
						alt="mochi-icon"
						className="mochi-info-icon"
					/>
				</Col>
			</Row>
		</div>
	);
}

export default Information;
