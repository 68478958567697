/*global chrome*/
import React, {useEffect} from "react";
import {Container} from "react-bootstrap";
import {app} from "./firebase-config";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import {accountOperations} from "./state/modules/account/"
import {wordOperations} from "./state/modules/word/";
// import env from 'dotenv'
import {useSelector, useDispatch} from "react-redux";
import Information from "./components/GeneralPage/Information";
import Login from "./components/GeneralPage/Login";
import Register from "./components/GeneralPage/Register";

function App() {
    const GOOGLE_TAG = process.env.REACT_APP_GOOGLE_TAG;

    const queryParams = new URLSearchParams(window.location.search);
    const page = queryParams.get('p')
    const accountState = useSelector((state) => state.account);
    const dispatch = useDispatch();
    // dispatch(accountOperations.fetchProfile());

    useEffect(() => {
        dispatch(accountOperations.fetchProfile());
    }, []);
    useEffect(() => {
        const script = document.createElement("script");
        const noScript = document.createElement("noscript");
        noScript.textContent = `<iframe src="https://www.googletagmanager.com/ns.html?id=${GOOGLE_TAG}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        script.textContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GOOGLE_TAG}');
    `;
        script.async = true;
        document.head.appendChild(script);
        document.body.appendChild(noScript);

        return () => {
            document.head.removeChild(script);
            document.body.removeChild(noScript);

        };
    }, []);


    return (
        <>
            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                <Routes>
                    <Route path="/" element={<Information/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="register" element={<Register/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
