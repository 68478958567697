// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// DEV

// const firebaseConfig = {
//   apiKey: "AIzaSyByJUxzEeVfucwzEWtpjAPe7dCg3pRvMIg",
//   authDomain: "mochi-cc593.firebaseapp.com",
//   projectId: "mochi-cc593",
//   storageBucket: "mochi-cc593.appspot.com",
//   messagingSenderId: "615395303407",
//   appId: "1:615395303407:web:89b4a30231b2768da8a01a",
//   measurementId: "G-LB35V70SNF",
// };

// Production
const firebaseConfig = {
    apiKey: "AIzaSyA7XZFrn_ue3sR8RNfXZdn74F0dle0GMpI",
    authDomain: "mochien-7880c.firebaseapp.com",
    projectId: "mochien-7880c",
    storageBucket: "mochien-7880c.appspot.com",
    messagingSenderId: "158797874498",
    appId: "1:158797874498:web:a029802e1da3f7edfdb4db",
    measurementId: "G-90NXGYPE7C",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
