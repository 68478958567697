/*global chrome*/

import "../component.css";

function PrimaryButton({children, style, className, onClick, disabled, id}) {
    return (
        <div
            className={`mochi_primary_btn_nt ${className} ${disabled && "disabled"}`}
            style={style}
            onClick={onClick}
            id={id}
        >
            {children}
        </div>
    );
}

export default PrimaryButton;
