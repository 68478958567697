import * as actions from './actions'
import { ApiClient } from '../../../AppClient'
let client = new ApiClient();
const ORIGIN = process.env.REACT_APP_ORIGIN;
const setCookie = (cookieName, cookieValue, day) => {
  document.cookie = cookieName + "=; Max-Age=0";
  const valuedCookie = cookieName + "=" + cookieValue;
  const date = new Date();
  date.setTime(+date + day * 86400000);
  document.cookie =
      valuedCookie +
      "; expires=" +
      date.toGMTString() +
      "; domain=" +
      ORIGIN +
      ";path=/";
};

const getCookie = (cookieName) => {
    const cookieNameArray = document.cookie.split(cookieName + "=");
    if (cookieNameArray.length === 2) {
        return cookieNameArray[1].split(";")[0];
    } else {
        return undefined;
    }
};

export const fetchProfile = () => {
  return (dispatch) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // const mochiTokenCookie = document.cookie.split("mochiToken=");
    let mochiToken = params.token;
    if (mochiToken !== undefined) {
        setCookie("mochiToken", mochiToken, 365);
    } else {
        mochiToken = getCookie("mochiToken");
    }
    // if (mochiTokenCookie.length === 2) {
    //   mochiToken = mochiTokenCookie[1].split(";")[0];
    // }
      // console.log({ mochiToken });
        if (mochiToken) {
          dispatch(actions.getAccountInformation(mochiToken));
          client
            .get("v3.0/profile", { params: { user_token: mochiToken } })
            .then((response) => {
              const result = response.data;
              // console.log({ result });
              if (result.code == "1") {
                dispatch(actions.fetchProfileCompleted(result.data));
              }
              // dispatch(actions.closeLoading());
            })
            .catch((error) => {
              const errorMsg = error.message;
              console.log(error.response.status);
              if ( error.response.status === 401 ) {
                dispatch(actions.getAccountInformation(""));
              }
              // dispatch(actions.fetchListFailed(errorMsg));
            });
        }
        
    }
}
export const fetchWordStatistic = () => {
  return (dispatch) => {
    const userToken = window.localStorage.getItem("mochiToken");
    if (userToken) {

      client
        .get("v3.0/words/summary-ios", { params: { user_token: userToken, lang: 'en' } })
        .then((response) => {
          const result = response.data;
          // console.log({ result });
          if (result.code == "1") {
            dispatch(actions.fetchWordStatisticCompleted(result.data));
          }
          // dispatch(actions.closeLoading());
        })
        .catch((error) => {
          const errorMsg = error.message;
          console.log({ errorMsg });
          // dispatch(actions.fetchListFailed(errorMsg));
        });
    }
  };
};

export const loginByThirdParty = (params, success = () => {}) => {
  return (dispatch) => {
    // dispatch(actions.getAccountInformation());
    client
      .post("v3.1/signin-with-fb-google", params)
      .then((response) => {
        const result = response.data;
        // console.log({ result });
        success(result)
        // dispatch(actions.fetchListCompleted(result));
        // dispatch(actions.closeLoading());
      })
      .catch((error) => {
        const errorMsg = error.message;
        // console.log({ errorMsg });
        // dispatch(actions.fetchListFailed(errorMsg));
      });
  };
};
export const loginByEmail = (params, success = () => {}, error = () =>{}) => {
  return (dispatch) => {
    // dispatch(actions.getAccountInformation());
    client
      .post("v3.0/signin-email", params)
      .then((response) => {
        const result = response.data;
        // console.log({ result });
        if (result.code == 1) {
          success(result);
        }
        else {
          error(result.msg, result.lang)
        }
        // dispatch(actions.fetchListCompleted(result));
        // dispatch(actions.closeLoading());
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log({ errorMsg });
        // dispatch(actions.fetchListFailed(errorMsg));
      });
  };
};
export const registerByEmail = (params, success = () => {}) => {
  return (dispatch) => {
    // dispatch(actions.getAccountInformation());
    client
      .post("v3.1/register-ios", params)
      .then((response) => {
        const result = response.data;
        // console.log({ result });
        success(result);
        // dispatch(actions.fetchListCompleted(result));
        // dispatch(actions.closeLoading());
      })
      .catch((error) => {
        const errorMsg = error.message;
        console.log({ errorMsg });
        // dispatch(actions.fetchListFailed(errorMsg));
      });
  };
};

export default {
  fetchProfile,
  loginByThirdParty,
  fetchWordStatistic,
  loginByEmail,
  registerByEmail,
};
