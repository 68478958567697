import ReactGA from 'react-ga';
ReactGA.initialize('UA-150440250-2');

export const gaEvent = (category, label, action) => {
    if (category && label && action) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
}

export const gaSignUpSuccess = () => {
    ReactGA.event({
        category: 'sign_up_page',
        action: 'sign_up',
        label: 'sign_up_success'
    });
}
export const getPageView = (url) => {
    ReactGA.pageview(url);
}