import * as types from "./types";

const INITIAL_STATE = {
  userToken: "",
  profileLoading: false,
  displayName: "Mochi",
  email: "mochimochi@gmail.com",
  avatar:
    "",
  expiredDay: "",
  startDay: "",
  totalWord: 0,

  reviewTime: "2022-01-05T11:20:09+07:00",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_PROFILE:
      return {
        ...state,
        userToken: action.payload,
      };
    case types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        displayName: action.payload.display_name,
        email: action.payload.email,
        avatar: action.payload.avatar,
        expiredDay: convertDate(action.payload.expired_day),
        startDay: convertDate(action.payload.created_at),
      };
    case types.FETCH_STATISTIC_SUCCESS:
      return {
        ...state,
        totalWord: action.payload.total,
        reviewCount: action.payload.review_count,
        reviewTime: action.payload.review_time,
        statistic: convertStatistic(action.payload.statistic),
      };

    default:
      return state;
  }
};

export default reducer;
function convertStatistic(data) {
  data.map(item => {
    return {
      id: data.proficiency,
      total: data.count
    };
  })
}

function convertDate(date) {
  if (date) {
    const dateObject = new Date(date);
    let day =
      dateObject.getDate() < 10
        ? "0" + dateObject.getDate()
        : dateObject.getDate();

    const month =
      dateObject.getMonth() + 1 < 10
        ? "0" + (dateObject.getMonth() + 1)
        : dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return day + "/" + month + "/" + year;
  } 
  else {
    return ''
  }
}