/*global gapi*/
/*global chrome*/
/*global auth2*/

import "./general.css";
import closeIcon from "../../assets/close-info.png";
import googleIcon from "../../assets/google.png";
import facebookIcon from "../../assets/facebook.png";
import appleIcon from "../../assets/apple.png";
import mochiIcon from "../../assets/Mochimeow.png";
import mochiNotice from "../../assets/Mochi-thong-bao.png";
import mochiCongras from "../../assets/mochi-chuc-mung.png";
import { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import PrimaryButton from "../commons/PrimaryButton";
import SecondaryButton from "../commons/SecondaryButton";
import MainModal from "../commons/MainModal";
import { accountOperations } from "../../state/modules/account";
import FacebookLogin from "react-facebook-login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	getAuth,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	OAuthProvider,
} from "firebase/auth";
import { gaEvent, getPageView } from "../../Ga";

const mochiAuth = getAuth();
mochiAuth.languageCode = "it";
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
facebookProvider.addScope("email");
facebookProvider.setCustomParameters({
	display: "popup",
});
appleProvider.addScope("email");
appleProvider.addScope("name");

/******/

function getParameterQuery() {
	let parameter = {};
	const params = new URLSearchParams(window.location.search);
	for (const param of params) {
		parameter[param[0]] = param[1];
	}
	return parameter;
}

function Login() {
	const params = getParameterQuery();
	const ORIGIN = process.env.REACT_APP_ORIGIN;
	let navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [showForgetModal, setShowForgetModal] = useState(false);
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [password, setPassword] = useState("");
	const [errorEmail, setErrorEmail] = useState("");
	const [errorPass, setErrorPass] = useState("");
	const [selectType, setSelectType] = useState("password");
	const dispatch = useDispatch();
	const handleChangeEmail = (event) => {
		const value = event.target.value.trim();
		const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		if (value == "") {
			setErrorEmail("*Email không được để trống, bạn kiểm tra lại nhé");
		} else if (!regex.test(value)) {
			setErrorEmail("*Email chưa chính xác, bạn kiểm tra lại nhé");
		} else {
			setEmail(value);
			setErrorEmail("");
		}
	};
	const handleChangePass = (event) => {
		const value = event.target.value.trim();
		if (value == "") {
			setErrorPass("*Mật khẩu không được để trống, bạn kiểm tra lại nhé");
		} else if (value.length < 6) {
			setErrorPass("*Mật khẩu chưa chính xác, bạn kiểm tra lại nhé");
		} else {
			setPassword(value);
			setErrorPass("");
		}
	};

	const handleSelectType = () => {
		setSelectType((prev) => (prev === "password" ? "text" : "password"));
	};
	const handleReload = () => {
		var laserExtensionId = process.env.REACT_APP_EXTENSION_ID;
		const extensionBox = document.querySelector(
			".Oq465NRHaOKegSCssrnhCql2WEIDuYU0"
		);
		if (extensionBox) {
			laserExtensionId = extensionBox.getAttribute("id");
		}
		// Make a simple request:
		chrome.runtime.sendMessage(
			laserExtensionId,
			{ getTargetData: "sendMessageLogin" },
			function (response) {}
		);
	};
	const handleSubmitLogin = () => {
		const disabled = errorEmail || errorPass || !email || !password;
		if (!disabled) {
			const data = new FormData();
			data.append("email", email);
			data.append("password", password);
			dispatch(
				accountOperations.loginByEmail(
					data,
					(result) => {
						if (result) {
							const userToken = result.user.user_token;
							setCookie("mochiToken", userToken, 365);
							const disableNewTab = getCookie("disableNewTab");
							if (disableNewTab !== null) {
								setCookie("disableNewTab", disableNewTab, 60);
							} else {
								setCookie("disableNewTab", 0, 60);
							}
							setCookie("defaultDict", "en-vn", 60);
							setShowLoginModal(true);
							handleReload();
						}
					},
					(error, type) => {
						if (error) {
							if (type == "messages.password_fails") {
								setErrorPass(error);
							}
							if (type == "messages.email_not_exits") {
								setErrorEmail(error);
							}
						}
					}
				)
			);
		}
	};
	const setCookie = (cookieName, cookieValue, day) => {
		document.cookie = cookieName + "=; Max-Age=0";
		const valuedCookie = cookieName + "=" + cookieValue;
		const date = new Date();
		date.setTime(+date + day * 86400000);
		document.cookie =
			valuedCookie +
			"; expires=" +
			date.toGMTString() +
			"; domain=" +
			ORIGIN +
			";path=/";
	};

	const getAndSaveToken = (data) => {
		dispatch(
			accountOperations.loginByThirdParty(data, (result) => {
				if (result) {
					const userToken = result.user.user_token;
					setCookie("mochiToken", userToken, 365);
					const disableNewTab = getCookie("disableNewTab");
					if (disableNewTab !== null) {
						setCookie("disableNewTab", disableNewTab, 60);
					} else {
						setCookie("disableNewTab", 0, 60);
					}
					setCookie("defaultDict", "en-vn", 60);
					setShowLoginModal(true);
					handleReload();
				}
			})
		);
	};
	const handleGoogleSignIn = () => {
		signInWithPopup(mochiAuth, googleProvider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential =
					GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				getAndSaveToken(data);
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
			});
	};
	const handleFacebookSignIn = () => {
		signInWithPopup(mochiAuth, facebookProvider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential =
					FacebookAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				console.log({ user });
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				data.append("trial_course", "1");

				getAndSaveToken(data);
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				console.error({ errorMessage });
				console.error({ email });
			});
	};
	const handleAppleSignIn = () => {
		signInWithPopup(mochiAuth, appleProvider)
			.then((result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = OAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				console.log({ user });
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				data.append("trial_course", "1");

				getAndSaveToken(data);
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				console.error({ errorMessage });
				console.error({ email });
			});
	};
	const responseFacebook = (res) => {
		const data = new FormData();
		data.append("email", res.email);
		data.append("name", res.name);
		data.append("provider", "facebook");
		data.append("provider_id", res.userID);
		data.append("lang", "vn");
		getAndSaveToken(data);
	};

	const getCookie = (cookieName) => {
		const cookieNameArray = document.cookie.split(cookieName + "=");
		if (cookieNameArray.length === 2) {
			return cookieNameArray[1].split(";")[0];
		} else {
			return;
		}
	};
	useEffect(() => {
		gaEvent(params.category, params.label, params.action);
		getPageView("/extension-page-login/login");
	}, []);
	return (
		<div
			className="user-information account-wrapper"
			style={{
				backgroundImage: 'url("./images/BG_extension_2.png")',
			}}
		>
			<Row>
				<Col
					md={{ span: 6, offset: 3 }}
					className="account-page-wrapper"
				>
					<div className="information-title-wrapper">
						<img
							src={closeIcon}
							alt="close-icon"
							className="info-close-icon"
							onClick={() => {
								window.close(window.location.href);
								// chrome.tabs.getCurrent((e) => {
								//   console.log("e", e);
								//   chrome.tabs.remove(e.id);
								// });
							}}
						/>
						<p className="info-title">Đăng nhập</p>
					</div>
					<div className="user-info-wrapper login-third-wrapper">
						<div className="login-third-wrapper">
							<h5>Đăng nhập tài khoản học MochiMochi</h5>
							<div id="my-signin2"></div>
							<SecondaryButton
								className="login-third-button google-btn"
								onClick={handleGoogleSignIn}
							>
								<img
									src={googleIcon}
									alt="google"
									className="google-icon"
								/>
								<p className="login-title" id="customBtn">
									Đăng nhập bằng G+
								</p>
							</SecondaryButton>
							{/* <GoogleLogin
                clientId="609613105784-sacs57o3adtrohvrk6s5t2mkc775tf0t.apps.googleusercontent.com"
                onSuccess={responseSuccessGoogle}
                onFailure={responseFailGoogle}
                cookiePolicy={"single_host_origin"}
                className="login-third-button google-btn"
                icon={false}
              >
                <img src={googleIcon} alt="google" className="google-icon" />
                <p className="login-title" id="customBtn">
                  Đăng nhập bằng G+
                </p>
              </GoogleLogin> */}
							<SecondaryButton
								className="login-third-button facebook-btn"
								onClick={handleFacebookSignIn}
							>
								<img
									src={facebookIcon}
									alt="google"
									className="facebook-icon"
								/>
								<p className="login-title">
									Đăng nhập bằng Facebook
								</p>
							</SecondaryButton>
							{/* <FacebookLogin
                appId="221903733471559"
                // autoLoad={true}
                fields="name,email,picture"
                callback={responseFacebook}
                cssClass="my-facebook-button-class facebook-btn login-third-button"
                icon="fa-facebook"
                textButton="Đăng nhập bằng Facebook"
              /> */}
							{/* <div id="appleid-signin"></div> */}

							<SecondaryButton
								className="login-third-button apple-btn"
								onClick={handleAppleSignIn}
							>
								<img
									src={appleIcon}
									alt="google"
									className="apple-icon"
								/>
								<p className="login-title">
									Đăng nhập bằng Apple
								</p>
							</SecondaryButton>
							<h5>HOẶC</h5>
						</div>
					</div>

					<div className="login-form-wrapper">
						<div className="email-input-wrapper">
							<input
								placeholder="Nhập email tài khoản học"
								className={`form-login-input ${
									errorEmail && "input-warning"
								}`}
								onChange={handleChangeEmail}
								autoComplete="off"
							/>
							{errorEmail && (
								<p className="error-text">{errorEmail}</p>
							)}
						</div>
						<div className="password-input-wrapper">
							<input
								placeholder="Nhập chính xác mật khẩu của bạn"
								className={`form-login-input ${
									errorPass && "input-warning"
								}`}
								onChange={handleChangePass}
								type={selectType}
								autoComplete="off"
							/>
							{errorPass && (
								<p className="error-text">{errorPass}</p>
							)}
							<p
								className="hide-show-btn"
								onClick={handleSelectType}
							>
								{selectType === "password" ? "Hiển thị" : "Ẩn"}
							</p>
						</div>
						<PrimaryButton
							className="login-button"
							disabled={
								errorEmail !== "" ||
								errorPass !== "" ||
								email === "" ||
								password === ""
							}
							onClick={handleSubmitLogin}
						>
							Đăng nhập
						</PrimaryButton>
						<p
							className="forget-pass"
							onClick={() => setShowForgetModal(true)}
						>
							Quên mật khẩu
						</p>
					</div>
					<p className="register-wrapper">
						Chưa có tài khoản?{" "}
						<span
							className="register-btn"
							onClick={() => {
								// const extensionId = chrome.runtime.id
								navigate("/register");
							}}
						>
							Tạo tài khoản học mới
						</span>
					</p>
					<div></div>

					<img
						src={mochiIcon}
						alt="mochi-icon"
						className="mochi-info-icon"
					/>
				</Col>
			</Row>
			{/* Modal login successfully */}
			<MainModal
				mascot={mochiCongras}
				show={showLoginModal}
				handleClose={() => {
					setShowLoginModal(false);
					window.close(window.location.href);
					// window.location.href =
					//   "https://mochidemy.com/extension-log-in-success";
					// navigate("/");
				}}
			>
				<div className="forget-notice-wrapper success__reload">
					<p className="boldText">
						Bạn đã đăng nhập thành công tài khoản học MochiMochi!!
					</p>
				</div>
			</MainModal>
			{/* Modal forget password */}
			<MainModal
				mascot={mochiNotice}
				show={showForgetModal}
				handleClose={() => setShowForgetModal(false)}
			>
				<div className="forget-notice-wrapper">
					<p className="boldText">
						Bạn hãy inbox cho Mochi để được hỗ trợ đổi mật khẩu nhé
					</p>
					<PrimaryButton
						onClick={() =>
							window.open("https://m.me/mochidemy", "_blank")
						}
						className="inbox-button"
					>
						INBOX CHO MOCHI
					</PrimaryButton>
				</div>
			</MainModal>
		</div>
	);
}

export default Login;
