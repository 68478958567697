/*global chrome*/

import "./general.css";
import closeIcon from "../../assets/close-info.png";
import googleIcon from "../../assets/google.png";
import facebookIcon from "../../assets/facebook.png";
import appleIcon from "../../assets/apple.png";

import backIcon from "../../assets/back.png";
import mochiIcon from "../../assets/Mochimeow.png";
import mochiRegisterIcon from "../../assets/Mochi-meomeo2.png";

import mochiNotice from "../../assets/Mochi-thong-bao.png";
import mochiCongras from "../../assets/mochi-chuc-mung.png";

import { useState, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";

import PrimaryButton from "../commons/PrimaryButton";
import SecondaryButton from "../commons/SecondaryButton";
import MainModal from "../commons/MainModal";
import { accountOperations } from "../../state/modules/account";
import { useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login";

import {
	getAuth,
	signInWithPopup,
	GoogleAuthProvider,
	FacebookAuthProvider,
	OAuthProvider,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { gaEvent, gaSignUpSuccess, getPageView } from "../../Ga";

const mochiAuth = getAuth();
mochiAuth.languageCode = "it";
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
facebookProvider.addScope("user_birthday");
appleProvider.addScope("email");
appleProvider.addScope("name");
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
const trackingURL = process.env.REACT_APP_TRACKING_URL;

function getParameterQuery() {
	let parameter = {};
	const params = new URLSearchParams(window.location.search);
	for (const param of params) {
		parameter[param[0]] = param[1];
	}
	return parameter;
}

function Register() {
	const params = getParameterQuery();
	// console.log(params);
	const ORIGIN = process.env.REACT_APP_ORIGIN;
	let navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [showForgetModal, setShowForgetModal] = useState(false);
	const [showRegisterModal, setShowRegisterModal] = useState(false);
	const [tab, setTab] = useState(0);
	const [rowId, setRowId] = useState("");
	const [userIp, setUserIp] = useState("");

	const [password, setPassword] = useState("");
	const [username, setUsername] = useState("");

	const [errorEmail, setErrorEmail] = useState("");
	const [errorPass, setErrorPass] = useState("");
	const [errorUsername, setErrorUsername] = useState("");
	const dispatch = useDispatch();

	const [selectType, setSelectType] = useState("password");
	const handleChangeEmail = (event) => {
		const value = event.target.value.trim();
		const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
		if (value == "") {
			setErrorEmail("*Email không được để trống, bạn kiểm tra lại nhé");
		} else if (!regex.test(value)) {
			setErrorEmail("*Email sai định dạng, bạn kiểm tra lại nhé");
		} else {
			setEmail(value);
			setErrorEmail("");
		}
	};

	useEffect(() => {
		gaEvent(params.category, params.label, params.action);
		getPageView("/extension-page-login/register");
	}, []);
	const handleChangePass = (event) => {
		const value = event.target.value.trim();
		if (value == "") {
			setErrorPass("*Mật khẩu không được để trống, bạn kiểm tra lại nhé");
		} else if (value.length < 6) {
			setErrorPass(
				"*Mật khẩu cần có ít nhất 06 ký tự, bạn kiểm tra lại nhé"
			);
		} else {
			setPassword(value);
			setErrorPass("");
		}
	};
	const handleChangeUsername = (event) => {
		const value = event.target.value;
		setUsername(value);

		if (value == "") {
			setErrorUsername(
				"*Tên hiển thị không được để trống, bạn kiểm tra lại nhé"
			);
		} else if (value.length < 3 || value.length > 15) {
			setErrorUsername("*Tên hiển thị cần có từ 3-15 ký tự");
		} else {
			setErrorUsername("");
		}
	};
	const handleBlur = (event) => {
		const value = event.target.value.trim();
		setUsername(value);
		if (value == "") {
			setErrorUsername(
				"*Tên hiển thị không được để trống, bạn kiểm tra lại nhé"
			);
		} else if (value.length < 3 || value.length > 15) {
			setErrorUsername("*Tên hiển thị cần có từ 3-15 ký tự");
		} else {
			setErrorUsername("");
		}
	};
	const handleSelectType = () => {
		setSelectType((prev) => (prev === "password" ? "text" : "password"));
	};

	const getAndSaveToken = (data) => {
		dispatch(
			accountOperations.loginByThirdParty(data, (result) => {
				if (result) {
					const userToken = result.user.user_token;
					setCookie("mochiToken", userToken, 365);
					const disableNewTab = getCookie("disableNewTab");
					if (disableNewTab !== null) {
						setCookie("disableNewTab", disableNewTab, 60);
					} else {
						setCookie("disableNewTab", 0, 60);
					}
					setCookie("defaultDict", "en-vn", 60);
					setShowRegisterModal(true);
				}
			})
		);
	};
	const setCookie = (cookieName, cookieValue, day) => {
		document.cookie = cookieName + "=; Max-Age=0";
		const valuedCookie = cookieName + "=" + cookieValue;
		const date = new Date();
		date.setTime(+date + day * 86400000);
		document.cookie =
			valuedCookie +
			"; expires=" +
			date.toGMTString() +
			"; domain=" +
			ORIGIN +
			";path=/";
	};
	const handleGoogleSignIn = () => {
		signInWithPopup(mochiAuth, googleProvider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential =
					GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				data.append("trial_course", "1");

				getAndSaveToken(data);
				gaSignUpSuccess();
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				console.error({ errorMessage });
				console.error({ email });
			});
	};
	const handleFacebookSignIn = () => {
		signInWithPopup(mochiAuth, facebookProvider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential =
					FacebookAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				// console.log({user});
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				data.append("trial_course", "1");

				getAndSaveToken(data);
				gaSignUpSuccess();
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				console.error({ errorMessage });
				console.error({ email });
			});
	};
	const handleAppleSignIn = () => {
		signInWithPopup(mochiAuth, appleProvider)
			.then(async (result) => {
				// This gives you a Google Access Token. You can use it to access the Google API.
				const credential = OAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				// The signed-in user info.
				const user = result.user;
				console.log({ user });
				const data = new FormData();
				const providerId =
					user.reloadUserInfo.providerUserInfo[0].rawId;
				data.append("email", user.email);
				data.append("name", user.displayName);
				data.append("provider", "google");
				data.append("provider_id", providerId);
				data.append("lang", "vn");
				data.append("trial_course", "1");

				getAndSaveToken(data);
				gaSignUpSuccess();
			})
			.catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				console.error({ errorMessage });
				console.error({ email });
			});
	};
	const responseFacebook = (res) => {
		const data = new FormData();
		data.append("email", res.email);
		data.append("name", res.name);
		data.append("provider", "facebook");
		data.append("provider_id", res.userID);
		data.append("lang", "vn");
		data.append("trial_course", "1");
		getAndSaveToken(data);
	};

	const handleSubmitLogin = () => {
		if (!isDisableSubmit()) {
			const data = new FormData();
			data.append("email", email);
			data.append("password", password);
			data.append("display_name", username);
			data.append("lang", "vn");
			data.append("trial_course", "1");

			dispatch(
				accountOperations.registerByEmail(data, async (result) => {
					if (result) {
						const userToken = result.user.user_token;
						setCookie("mochiToken", userToken, 365);
						const disableNewTab = getCookie("disableNewTab");
						if (disableNewTab !== null) {
							setCookie("disableNewTab", disableNewTab, 60);
						} else {
							setCookie("disableNewTab", 0, 60);
						}
						setCookie("defaultDict", "en-vn", 60);
						setShowRegisterModal(true);
						setTab(0);
						gaSignUpSuccess();
					}
				})
			);
		}
	};
	const isDisableSubmit = () => {
		return (
			errorUsername ||
			errorEmail ||
			errorPass ||
			!email ||
			!password ||
			!username
		);
	};
	const getCookie = (cookieName) => {
		const cookieNameArray = document.cookie.split(cookieName + "=");
		if (cookieNameArray.length === 2) {
			return cookieNameArray[1].split(";")[0];
		} else {
			return;
		}
	};
	return (
		<div
			className="user-information account-wrapper"
			style={{
				backgroundImage: 'url("./images/BG_extension_2.png")',
			}}
		>
			<Row>
				<Col
					md={{ span: 6, offset: 3 }}
					className="account-page-wrapper"
				>
					<div className="information-title-wrapper">
						{tab == 0 && (
							<img
								src={closeIcon}
								alt="close-icon"
								className="info-close-icon"
								onClick={() => {
									window.close(window.location.href);
									// chrome.tabs.getCurrent((e) => {
									//   console.log("e", e);
									//   chrome.tabs.remove(e.id);
									// });
								}}
							/>
						)}
						{tab == 1 && (
							<img
								src={backIcon}
								alt="back-icon"
								className="info-close-icon"
								onClick={() => {
									setTab(0);
								}}
							/>
						)}
						<p className="info-title">Tạo tài khoản mới</p>
					</div>

					<div className="user-info-wrapper login-third-wrapper">
						<div className="login-third-wrapper">
							<h5>Bạn muốn tạo tài khoản bằng cách nào nhỉ?</h5>
							<img
								src={mochiRegisterIcon}
								alt="mochi"
								className="register-icon"
							/>
							{tab === 0 && (
								<>
									<SecondaryButton
										className="login-third-button google-btn"
										onClick={handleGoogleSignIn}
									>
										<img
											src={googleIcon}
											alt="google"
											className="google-icon"
										/>
										<p className="login-title">
											Tạo tài khoản bằng G+
										</p>
									</SecondaryButton>
									<SecondaryButton
										className="login-third-button facebook-btn"
										onClick={handleFacebookSignIn}
									>
										<img
											src={facebookIcon}
											alt="google"
											className="facebook-icon"
										/>
										<p className="login-title">
											Tạo tài khoản bằng Facebook
										</p>
									</SecondaryButton>
									{/* <FacebookLogin
                    appId="221903733471559"
                    // autoLoad={true}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="my-facebook-button-class facebook-btn login-third-button"
                    icon="fa-facebook"
                    textButton="Tạo tài khoản bằng Facebook"
                  /> */}
									<SecondaryButton
										className="login-third-button apple-btn"
										onClick={handleAppleSignIn}
									>
										<img
											src={appleIcon}
											alt="google"
											className="apple-icon"
										/>
										<p className="login-title">
											Tạo tài khoản bằng Apple
										</p>
									</SecondaryButton>

									<h5>HOẶC</h5>
									<SecondaryButton
										className="login-third-button create-acc-btn"
										onClick={() => setTab(1)}
									>
										<p className="login-title">
											Tự tạo tài khoản với email
										</p>
									</SecondaryButton>

									<p className="register-wrapper">
										Bạn đã có tài khoản?{" "}
										<span
											className="register-btn"
											onClick={() => navigate("/login")}
										>
											Đăng nhập ngay
										</span>
									</p>
								</>
							)}
						</div>

						{tab === 1 && (
							<div className="register-form-wrapper">
								<div className="username-wrapper">
									<input
										placeholder="Tên của bạn"
										className={`form-login-input ${
											errorUsername && "input-warning"
										}`}
										value={username}
										onChange={handleChangeUsername}
										onBlur={handleBlur}
									/>
									{errorUsername && (
										<p className="error-text">
											{errorUsername}
										</p>
									)}
								</div>
								<div className="email-input-wrapper">
									<input
										placeholder="Nhập email tài khoản học"
										className={`form-login-input ${
											errorEmail && "input-warning"
										}`}
										onChange={handleChangeEmail}
									/>
									{errorEmail && (
										<p className="error-text">
											{errorEmail}
										</p>
									)}
								</div>
								<div className="password-input-wrapper">
									<input
										placeholder="Tạo mật khẩu (dễ nhớ chút nhé ^^)"
										className={`form-login-input ${
											errorPass && "input-warning"
										}`}
										onChange={handleChangePass}
										type={selectType}
									/>
									{errorPass && (
										<p className="error-text">
											{errorPass}
										</p>
									)}
									<p
										className="hide-show-btn"
										onClick={handleSelectType}
									>
										{selectType === "password"
											? "Hiển thị"
											: "Ẩn"}
									</p>
								</div>

								<PrimaryButton
									className="login-button"
									disabled={isDisableSubmit()}
									onClick={handleSubmitLogin}
								>
									Tạo tài khoản
								</PrimaryButton>
							</div>
						)}
					</div>
					<img
						src={mochiIcon}
						alt="mochi-icon"
						className="mochi-info-icon"
					/>
				</Col>
			</Row>
			{/* Modal login successfully */}
			<MainModal
				mascot={mochiCongras}
				show={showRegisterModal}
				handleClose={() => {
					setShowRegisterModal(false);
					window.close(window.location.href);

					// window.location.href = "https://mochidemy.com/extension-log-in-success";
					// navigate("/");
				}}
			>
				<div className="forget-notice-wrapper">
					<p className="boldText">
						Bạn đã tạo tài khoản thành công và mở khoá các tính năng
						của extension
					</p>
				</div>
			</MainModal>
			{/* Modal forget password */}
			<MainModal
				mascot={mochiNotice}
				show={showForgetModal}
				handleClose={() => setShowForgetModal(false)}
			>
				<div className="forget-notice-wrapper">
					<p className="boldText">
						Bạn hãy inbox cho Mochi để được hỗ trợ đổi mật khẩu nhé
					</p>
					<PrimaryButton
						onClick={() =>
							window.open(
								"https://learn.mochidemy.com/",
								"_blank"
							)
						}
						className="inbox-button"
					>
						INBOX CHO MOCHI
					</PrimaryButton>
				</div>
			</MainModal>
		</div>
	);
}

export default Register;
